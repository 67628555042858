
@font-face {
  font-family: Boston;
  src: url(./assets/fontquestionary/BostonRegular.otf);
  font-weight: normal;
}

@font-face {
  font-family: Boston;
  src: url(./assets/fontquestionary/BostonBold.otf);
  font-weight: bold;
}

@font-face {
  font-family: NotoSerif;
  src: url(./assets/fontvideo/NotoSerif-Regular.ttf);
  font-weight: normal;
}

@font-face {
  font-family: NotoSerif;
  src: url(./assets/fontvideo/NotoSerif-Bold.ttf);
  font-weight: bold;
}


@font-face {
  font-family: ProximaNova;
  src: url(./assets/fontvideo/ProximaNova-Regular.otf);
  font-weight: normal;
}

@font-face {
  font-family: ProximaNova;
  src: url(./assets/fontvideo/ProximaNovaBold.otf);
  font-weight: bold;
}

.allFontsToBeBoston h1,h2,h3,h4,h5,h6,p span,input,label {
  font-family: Boston !important;
}

 .allFontsToBeBoston .MuiStepLabel-label{
  font-family: Boston !important;
}


.stepBoxControll .MuiButton-label{
  font-family: Boston !important;
}

.allFontsToBeBoston .MuiInputBase-input{
  font-family: Boston !important;
}

.greyPlate{
  background: #F8F8F8;
  padding: 10px;
  margin: 10px 10px;
  text-align: center;
}

/* @font-face {
  font-family: Boston Bold;
  src: url(./assets/fontquestionary/BostonBold.otf);
} */

html,
body {
  font-family: ProximaNovaRegular, sans-serif;
}


input, textarea, select {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.questionaryView h1,h2,h3,h4,h5,h6,p,span,label, input, .timeSlots .item{
  font-family: Boston!important;
}


.doctor-panel{
  /* font-family: 'Courier New', Courier, monospace; */
  font-family: ProximaNova;
}

.doctor-panel h3, strong, h6{
  font-family: NotoSerif;
  font-weight: bold;
}

.doctor-panel a,p{
  font-family: ProximaNova;
}

.item-number{
  font-family: NotoSerif;
}

.item-number-green{
  font-family: NotoSerif;
}

.button-red{
  font-family: NotoSerif;
}

.resize-window{
  display: none;
}

.login{
  /* font-family: 'Courier New', Courier, monospace; */
  display: flex;
  flex-direction: row;
  top: 10vh;
  height: 90vh;
  position: relative;
}

.login h1, h2, .button{
  font-family: NotoSerif;
}

.login label {
  font-family: ProximaNova;
}

.data-grid{
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(50%, 2fr))
}

.data-grid .item{
  padding: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.data-grid .item p{
  font-size: 18px;
}

.data-grid .item h3{
  font-size: 24px;
}

.data-grid .item-text{
  display: flex;
  flex-direction: column;
  padding: 0 5%;
}


.data-grid .item-number{
  background: #F8F8F8;
  font-size: 32px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  border-radius: 12px;
  padding: 4% 6%;
  display: flex;
  justify-content:center;
  align-items: center;
  align-content: center;
  height: auto;
  width: 7vw;
  min-width: 7vw;
}

.data-grid .item-number-green{
  background: #5CC8BD;
  font-size: 32px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  border-radius: 12px;
  padding: 4% 6%;
  color: #fff;
  display: flex;
  justify-content:center;
  align-items: center;
  align-content: center;
  height: auto;
  width: 7vw;
  min-width: 7vw;
}

.login-image{
  flex: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-image: url("./assets/login-man.png");
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.login-image-analytics{
  flex: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-image: url("./assets/analytics-front.png");
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.login-image-analytics h1{
  font-size: 80px;
  text-align: center;
  color: #ffff;
  font-weight: bold;
}

.login-image h1{
  font-size: 80px;
  text-align: center;
  color: #ffff;
  font-weight: bold;
}

.login-form{
  padding: 1%;
  flex: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.login-footer p{
  color: #C3C3C3;
  font-size: 16px;
}

.login-inputs{
  display: flex;
  flex-direction: column;
  padding: 5% 5% 5% 15%;
  width: 37vw;
}

.login-inputs h2{
  font-size: 40px;
  margin: 0.5em 0;
  padding:0
}

.login-inputs img{
  height: auto;
  width: 7vw;
}

.login-form-fields{
  display: flex;
  flex-direction: column;
  width: 25vw;
}

.login-form-fields label{
  color: #19191A;
}

.login-form-fields input {
  border: none!important;
  background: #F8F8F8;
  border-radius: 8px;
  text-align: left;
  padding: 4% 1% 4% 5%;
  display: block;
  margin: 6% 0;
}


.login-form-fields input::selection {
  border: none!important;
  background: #F8F8F8;
  border-radius: 8px;
  text-align: left;
  padding: 2% 1% 2% 2%;
  display: block;
  max-width: 25vw;
  margin: 2% 0;
}

.login-form .button{
  padding: 4% 3% 4% 6%;
  display: inline-block;
  width: 25vw!important;
  border-color: #C4385B;
  background: #C4385B 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  text-align: left;
  color: #fff;
  margin: 1em 0;
  font-size: 22px;
  min-width: 0!important;
  font-weight: bold;
}

.login-footer{
  display: flex;
  justify-content: space-around;
}



.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.video-grid {
  height: 90vh;
  min-height: 90vh;
  background: #000;
  display: flex;
  flex-direction: row;
}

.video-grid p{
  font-family: ProximaNova !important;
}

.video-grid span{
  font-family: ProximaNova !important;
}


.control-bar {
  min-height: 10vh;
  max-height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.controls {
  flex: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.timer {
  position: relative;
  color: black;
  /* bottom: -70%; */
  font-size: 20px;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
}


.disabled-btn{
  background: #E0E0E0!important;
  border-color: #E0E0E0!important;
  color: #fff!important;
}

.controls img {
  width: 3.5em;
  height: 3.5em;
  cursor: pointer;
}

.controls img:hover{
  transform: scale(1.2)
}

.control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 2%;
  padding: 1% 0;
}

#local-media {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabledVideoText{
  color: white;
  position: absolute;
  z-index: -2;
}

.enabledVideoText{
  z-index: 100;
}


.disabledVideoTextLocal{
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
}

.enabledVideoTextLocal{
  z-index: 100;
}

#logo {
  position: absolute;
  top: 2%;
  left: 1%;
}

#logo img {
  height: 91px;
  width: auto;
}

#remote-media-div {
  position: absolute;
  top: 3%;
  right: 11%;
  width: 15vw;
  height: 25vh;
}

.muted-video {
  background: #2e2e2e;
  position: absolute;
  top: 3%;
  right: 11%;
  width: 15vw !important;
  height: 35vh !important;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.muted-video-text {
  font-size: 26px;
  color: #fff;
  text-align: center;
}

#remote-media-div video {
  width: 95%;
  height: 35vh;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}

#local-media video {
  width: 45%;
  height: 100%;
  object-fit: cover;
}

#questionaryRoot {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: Boston;
}

.questionaryView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #fafafa;
  flex: 1;
  color: #000;
  min-height: 85vh;
  font-family: "Boston";
}

.stepBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #fff;
  border: 1px solid #a5d782;
  border-radius: 10px;
  padding: 1% 3% 2% 3%;
  margin: 1%;
  width: 40%;
}

.input-alert input{
  border-color: #ff0000;
}

.stepBoxFinal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: #fff;
  border: 1px solid #a5d782;
  border-radius: 10px;
  margin: 3%;
  width: 40%;
}

.stepBox button {
  padding: 1em 5em;
  font-size: 18px;
  font-weight: normal;
}

.stepBoxControll {
  margin-top: 2%;
}

.form {
  display: flex;
  flex-direction: column;
}

.davcnaForm h1 {
  color: #a5d782;
  font-size: 44px;
  font-weight: bold;
}

.davcnaForm p {
  font-size: 18px;
  font-weight: 500;
  padding: 2% 0;
}

.davcnaFormCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  padding: 0;
}

.paddedBox {
  padding: 2% 4% 4% 4%;
  text-align: center;
}

.davcnaFormCenter img {
  max-width: 100%;
  height: auto;
}

.painLevel {
  margin: 1em 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20%, 3fr));
}

.painLevel input[type="checkbox"] {
  margin: 1em 0;
  min-width: 1.3em;
  min-height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.roundedChecbox {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.dataDivider {
  margin: 5% 0;
}

.dataDivider h2 {
  font-size: 22px;
  font-weight: bold;
}

.dataDivider h3 {
  font-size: 18px;
  font-weight: bold;
  color: #555555;
  display: flex;
  justify-content: space-between;
}

.dataDivider h4 {
  font-size: 18px;
  font-weight: normal;
  color: #555555;
}

.userData {
  font-size: 18px;
  font-weight: normal;
  color: #555555;
  margin-right: 13em;
}

input {
  border: 1px solid #a5d782 !important;
  border-radius: 8px !important;
}

input::hover {
  border: 1px solid #a5d782 !important;
}

input::focus {
  border: 1px solid #a5d782 !important;
}

input::selected {
  border: 1px solid #a5d782 !important;
}

#greenButton:hover {
  background: #a5d782;
}

#greenButton {
  background: #a5d782;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #fff !important;
}

.MuiStepIcon-root {
  color: #a5d782 !important;
  border: 1px solid #fff;
  border-radius: 100%;
}

.MuiStepLabel-label {
  color: #fff !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #fff !important;
}

.MuiStepConnector-line {
  border-color: #fff !important;
}

.MuiStepIcon-text {
  fill: #fff0 !important;
}

/* Styles for video */

.videoWelcomeScreen {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-family: NotoSerif;

}

.videoWelcomeScreen h1 {
  font-size: 44px;
  font-weight: bold;
}

.videoWelcomeScreen h3 {
  font-size: 28px;
  font-weight: 400;
  font-family: ProximaNova !important;
}



.videoWelcomeScreen button {
  margin-top: 1%;
  background: #5cc8bd;
  border-radius: 8px;
  font-size: 26px;
  color: #fff;
  border: 1px solid #5cc8bd;
  padding: 1% 2.5%;
}

.videoWelcomeScreen button:hover {
  margin-top: 1%;
  background: #25e4d1;
  border-radius: 8px;
  font-size: 26px;
  color: #fff;
  border: 1px solid #25e4d1;
  padding: 1% 2.5%;
  transform: scale(1.1, 1.1);
}

.cancelingCall {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1% 2.5%;
  background: #fff;
  border-radius: 20px;
  font-size: 26px;
  color: #aeaeae;
  min-width: 40vw;
  z-index: 1000;
}

.cancelingCallAction {
  border-top: 1px solid #aeaeae;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 5%;
  align-items: center;
}

.potrdi {
  border: 3px solid #5cc8bd;
  color: #5cc8bd;
  margin-left: 5%;
  padding: 0.35em 1.5em;
  border-radius: 5px;
  cursor: pointer;
}

/* feedback */

#feedbackRoot {
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #fafafa;
}

.feedbackView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex: 1;
  color: #000;
  max-width: 50vw;
}

.feedbackView h1, span{
  font-family: NotoSerif !important;
}


.feedbackView h2, h3, h4,h5, input{
  font-family: ProximaNova;
}

.quick-analytics{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 3fr));
  margin: 2em 0;
}

.quick-analytics .item{
  width: 100%;
  padding: 0 5%;
  color: #fff;
}

.item h1{
  font-family: ProximaNova!important;
  font-size: 22px;
  color: #fff!important;
}

.item h3{
  color: #fff!important;
}

.item-one{
  color: #fff;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 5%;
  border-radius: 10px;
  height: 9em;
  background: url('./assets/steviloPosvetov.png'), linear-gradient(#5CC8BD, #ACE3DD);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: bottom right;
}

.item-two{
  color: #fff;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 5%;
  border-radius: 10px;
  height: 9em;
  background: url('./assets/trajanjePosveta.png'), linear-gradient(#C4385B, #F4D7D4);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: bottom right;
}

.item-three{
  color: #fff;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 5%;
  border-radius: 10px;
  height: 9em;
  background: url('./assets/napotitevZavarovancev.png'), linear-gradient(#5CC8BD, #3E8881);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: bottom right;
}


.stepBoxControllFeedback {
  display: flex;
  justify-content: center;
  margin-top: 2% 0;
}

.stepBoxFeedback {
  background: #fff;
  border-radius: 30px;
  padding: 4%;
}

.feedbackFrom {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  text-align: center;
}

.feedbackFormCentered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  text-align: center;
  background: #fff;
  border-radius: 30px;
}

.feedbackFormCentered h1 {
  font-size: 44px;
  font-weight: bold;
  text-align: center;
}

.feedbackFormCentered h2 {
  margin: 4% 0;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

.feedbackFormCentered h3 {
  font-size: 24px;
  font-weight: normal;
  text-align: center;
}

.feedbackForm h1 {
  font-size: 44px;
  font-weight: bold;
  text-align: center;
}

.feedbackForm h2 {
  margin: 4% 0;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

.feedbackForm h3 {
  font-size: 24px;
  font-weight: normal;
  text-align: center;
}

.feedbackFormRating {
  padding: 8% 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18%, 3fr));
}

.feedbackFormRatingOptions {
  padding: 0 0 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 3fr));
}

.halfGrid {
  display: flex;
  margin: 0 0 8% 0;
  justify-content: center;
  align-self: center;
  align-content: center;
}

.feedbackFormRatingOptions .item {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: 1px solid #a6a6a6;
  border-radius: 8px;
  margin: 1em 1em;
}

.feedbackFormRatingOptions .item h5 {
  font-size: 16px;
  padding: 0;
  margin: 1em;
}

.feedbackFormRatingOptions .selected {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: 1px solid #a6a6a6;
  background: #5cc8bd !important;
  border-color: #5cc8bd !important;
  border-radius: 8px;
  margin: 4% 2%;
  color: #fff !important;
}

.feedbackFormRatingOptions .item {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 8px;
  margin: 4% 2%;
  cursor: pointer;
}

.smiley h4 {
  font-weight: 500;
}

.feedbackFormRatingOptions > .selected h5 {
  font-size: 16px !important;
  padding: 0 !important;
  margin: 1em !important;
}

.feedbackFormRatingOptions > .selected > .item h5 {
  font-size: 16px !important;
  padding: 0 !important;
  margin: 1em !important;
}

.feedbackFormRatingOptions > .item > h5 {
  font-size: 12px !important;
  padding: 0 !important;
  margin: 1em !important;
  font-weight: bold;
}

.feedbackFormRatingOptions > .selected h5 {
  font-size: 12px !important;
  padding: 0 !important;
  margin: 1em !important;
}

.feedbackFormRating .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
}

.button {
  min-width: 20em !important;
  /* padding: 0 8em!important; */
}

#blueButton {
  background: #5cc8bd;
  color: #fff;
}

#blueButton:hover {
  background: #5cc8bd;
  color: #fff;
}

#feedbackRoot input {
  border: 1px solid #a6a6a6 !important;
  border-radius: 8px !important;
}

#feedbackRoot input::hover {
  border: 1px solid #a6a6a6 !important;
}

#feedbackRoot input::focus {
  border: 1px solid #a6a6a6 !important;
}

#feedbackRoot input::selected {
  border: 1px solid #a6a6a6 !important;
}

.MuiButton-text {
  padding: none !important;
}

.timeSlots {
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  min-height: 23vh;
  max-height: 35vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 1em;
}

/* Works on Firefox */
.timeSlots {
  scrollbar-width: thin;
  scrollbar-color: #e0e0e0 #a5d782;
}

/* Works on Chrome, Edge, and Safari */
.timeSlots::-webkit-scrollbar {
  width: 6px;
}

.timeSlots::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.timeSlots::-webkit-scrollbar-thumb {
  background-color: #a5d782;
  border-radius: 20px;
  border: 2px solid #a5d782;
}

.timeSlots .item {
  display: flex;
  justify-content: center;
  margin: 1em 0;
  border: 1px solid #a5d782;
  border-radius: 5px;
  padding: 15px 100px;
  color: #a5d782;
  cursor: pointer;
  font-family: Boston;
}

.timeSlots .selected {
  display: flex;
  justify-content: center;
  margin: 1em 0;
  background: #566964;
  border: 1px solid #566964;
  border-radius: 5px;
  padding: 15px 100px;
  color: #fff;
}

.timeSlots .item h5 {
  color: #a1cc81;
  font-size: 24px;
  padding: 0;
  margin: 0;
}


.iti__flag {background-image: url("/src/assets/dateLeft.svg");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("/src/assets/dateLeft.svg");}
}


.value textarea{
  color: #19191A
}

/* SECTIONS */

.section{
  width: calc(100% - 270px);
  left: 270px;
  position: absolute;
  height: 79vh;
  top: 10vh;
}

.analytics-section{
  width: 100%;
  padding: 0 3vw;
  position: absolute;
  height: 79vh;
  top: 10vh;
}

.section-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 1% 0 0 0;
}

.section-header h3{
  font-size: 38px;
  color: #19191A;
  margin: 0.3em 0; 
}

.section-container{
  padding: 0% 10% 0 5%;
  height: inherit
}

.section-content{
}

.section-content h5{
  font-size: 22px;
  font-weight: 400;
  background: #fff;
  margin: 1em 0;
}

.filters{
  margin: 0.5em 0;
}

.filters h5{
  font-size: 18px;
  margin: 0.3em 1.5em 0.3em 0em;
  cursor: pointer;
}

.filters-nastavitve h5{
  font-size: 22px;
  margin: 0.6em 1.5em 0.3em 0em;
}

.button-red {
  display: inline-block!important;
  background: #C4385B!important;
  border-radius: 12px!important;
  color: #fff!important;
  border: 1px solid #C4385B!important;
  padding: 1% 3%!important;
  font-size: 24px!important;
  cursor: pointer!important;
}

.button-grey {
  display: inline-block!important;
  background: #f8f8f8!important;
  border-radius: 12px!important;
  color: #7b7b7b!important;
  border: 1px solid #f8f8f8!important;
  padding: 1% 3%!important;
  font-size: 16px!important;
  cursor: pointer!important;
}

.counter-container{
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(255, 255, 255);
  padding: 3% 5%;
  border-radius: 12px;
  background: #F8F8F8;
}

.grey-container{
  background: #F8F8F8;
  border-radius: 12px;
  padding: 20px;
}

.grey-container-calendar{
  background: #F8F8F8;
  border-radius: 12px;
  /* padding: 20px 40px; */
}

.h-48{
  min-height: 7rem;
}

.heading-text{
  font-size: 24px;
  color: #19191A;
  margin: 0.3em 0;
  font-weight: bold;
}

.grey-container h5{
  background: inherit;
  font-weight: bold;
}

.pacientCard{
  margin: 2em 0;
  width: 50vw;
}

.doctorCard{
  margin: 0 0 2em 0;
  width: 50vw;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 3fr))
}

.doctorCard h5{
  font-size: 18px;
}

.doctorCardRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin: 3% 0
}

.doctorCardRow .value{
  background:  #FFFFFF;
  border-radius:12px;
  padding: 2% 10% 2% 2%;
  color: #19191A;
  font-size: 20px;
}

.doctorCardRow input{
  border: none!important;
  background: #F8F8F8;
  border-radius: 12px;
  width: calc(100% - 10%);
  padding: 3% 3%;
}

.pacientCardRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin: 3% 0
}

.pacientCardRow .value{
  background:  #FFFFFF;
  border-radius:12px;
  padding: 2% 10% 2% 2%;
  color: #19191A;
  font-size: 20px;
  flex: 1;
}

.pacientCardRow .key{
  font-weight: bold;
  padding: 2% 10% 2% 0%;
  color: #575757;
  font-size: 20px;
  flex:1;
}


.pacientCardRowReversed{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  margin: 3% 0
}

.pacientCardRowReversed .value{
  background:  #FFFFFF;
  border-radius:12px;
  /* padding: 2% 10% 2% 2%; */
  color: #19191A;
  font-size: 20px;
}

.pacientCardRowReversed .key{
  font-weight: bold;
  padding: 2% 10% 2% 0%;
  color: #575757;
  font-size: 20px;
}


.eventHolder{
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overflow-y-auto{
  overflow-y: hidden!important;
}

.popUp{
  position: absolute;
  z-index: 1000;
  background: #ffff;
  border-radius: 15px;
  padding: 1% 5%;
  width: 50vw;
  top: 50%;
  right: 0;
  transform: translate(-30%, -50%);
}

.popUp h3{
  font-size: 24px;
  text-align: center;
  margin-bottom: 5%
}

.popUp textarea{
  border: 1px solid #A6A6A6 !important;
  border-color: #A6A6A6;
  min-height: 20vh;
  width: 97%;
  border-radius: 8px;
  padding: 1%
}

/*TABLES*/

.nyd-activityTable{
  display: flex;
  flex-direction: column;
}

.nyd-activityTable-item{
  margin: 0.5em 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 3fr));
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 1em 1em; 
  background: #fff;
  border-radius: 15px;
}

.nyd-activityTable-item-analytics{
  margin: 0.5em 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 3fr));
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 1em 1em; 
  background: #fff;
  border-radius: 15px;
}

.nyd-activityTable-pacients .nyd-activityTable-item{
  margin: 0.5em 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 3fr));
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 1em 1em; 
  background: #fff;
  border-radius: 15px;
}

.nyd-activityTable-options{
  display: flex;
  flex-direction: row;
}

.nyd-activityTable-options-analytics{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.option{
  margin: 0 1.5em;
  cursor: pointer;
}


.filter{
  background-color: #000;
  opacity: 0.65;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.MuiInput-underline.Mui-disabled:before{
  border: none !important;
}


.MuiOutlinedInput-multiline{
  border: 1px solid #a5d782 !important;
}

.MuiOutlinedInput-notchedOutline{
  border: none !important;
}

/* .optionsPopOver{
  z-index: 10000;
  background: #fff;
  width: 14em;
  border: 1px solid #5A5A5A;
  left: 11%;
  bottom: 9%;
  border-radius: 5px;
  position: sticky;
} */

.optionsPopOver{

position: absolute;
    top: 30%;
    left: 0;
    transform: translate(110%, 50%);
    z-index: 10000;
    background: #fff;
    width: 25vw;
    padding: 2%;
    border: 1px solid #5A5A5A;
    border-radius: 5px;
   
}

.optionsPopOver h6{
  color: black;
    font-size: 1.3em;
    margin-bottom: 0.7em;
}

.xbutton{
  text-align: center;
  background: #C4385B;
  width: 5%;
  position: absolute;
  right: 11px;
  color: white;
  top: 11px;
  border-radius: 4px;
  pointer-events: cursor;
  cursor: pointer;
}


.singleDayPopUp{
  position: absolute;
  top: 30%;

  left: 0;
  transform: translate(110%, 50%);
  z-index: 10000;
  background: #fff;
  width: 25vw;
  padding: 2%;
  border: 1px solid #5A5A5A;
  border-radius:5px;
}


.singleDayPopUp .timePicker{
  display: flex;
  margin: 1em 0;
}

.centeredPopUp{
  top: 50%!important;
}


.react-calendar{
  width: 100% !important;
}

.timePicker select{
  padding: 2%!important;
   margin: 0 2%!important;
}


/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  /* Feedback CSS styling for Mobile */

  .resize-window{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    background: #F4D7D4;
    border-radius: 20px;
    min-width: 75vw;
  }

  .resize-window-header h3{
    font-size: 16px!important;
  }

  .resize-window-header{
    background: #F4D7D4;
    text-align: center;
    padding: 5%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .resize-window-header img{
    margin: 1em!important;
  }

  .video-grid {
    height: 80vh!important;
    min-height: 80vh!important;
    background: #000;
    display: flex;
    flex-direction: row;
  }

  html,
  body {
    font-family: ProximaNovaRegular, sans-serif;
    height: 100%;
  }

  .button {
    min-width: initial !important;
    /* padding: 0 8em!important; */
  }

  .timer {
    position: absolute;
    color: white;
    bottom: -18%;
    font-size: 26px;
    left: -40%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.2);
    padding: 2px 5px;
  }


  .feedbackFormRatingOptions .item {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 8px;
    margin: 4% 2%;
    max-height: 6vh;
  }

  #feedbackRoot {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fafafa;
    overflow-x: hidden;
  }

  .feedbackFormRatingOptions > .selected h5 {
    color: #fff;
  }

  .feedbackFormRatingOptions > .selected {
    background: red;
    max-height: 6vh;
  }

  .feedbackForm h1 {
    font-size: 18px;
  }

  .feedbackForm h2 {
    font-size: 16px;
    margin: 3em 0 2em 0;
    padding: 0 9%;
  }

  .feedbackForm h3 {
    font-size: 12px;
    padding: 0 4em;
  }

  .feedbackFormCentered h1 {
    font-size: 28px;
  }

  .feedbackFormCentered h2 {
    font-size: 20px;
    margin: 0.5em 0 2em 0;
  }

  .feedbackView {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex: 1 1;
    color: #000;
    max-width: 100vw;
    margin: 1em 0;
  }

  .feedbackFormCentered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex: 1 1;
    color: #000;
    max-width: 100vw;
    margin: 1em 0;
    padding: 2%;
    background: none;
  }

  .feedbackFormRating {
    padding: 8% 2%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 3fr));
  }

  .stepBoxFeedback {
    background: none;
    border-radius: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .makeStyles-instructions-3 {
    flex: 1;
  }

  .feedbackFormRating .item img {
    width: auto;
    height: 6vh;
  }

  .feedbackFormRating .item h4 {
    font-size: 12px;
    font-weight: normal;
  }

  .MuiOutlinedInput-input {
    padding: 11.5px 10px;
  }

  #logo {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0em 1em;
  }

  #logo img {
    height: 6vh;
  }

  .feedbackFormRatingOptions {
    padding: 0 5%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50%, 3fr));
  }

  .halfGrid {
    grid-template-columns: repeat(auto-fill, minmax(50%, 3fr));
  }

  .fullRow {
    grid-template-columns: repeat(auto-fill, minmax(100%, 3fr));
  }

  .imageResponsive {
    height: auto !important;
    width: 80% !important;
  }

  /* Qeustionary CSS styling for Mobile */

  .makeStyles-padded-4 {
    padding: 0 !important;
  }

  .MuiSvgIcon-root {
    width: 5px !important;
    height: 5px !important;
  }

  .MuiStepConnector-alternativeLabel {
    top: 3px !important;
  }

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-size: 10px !important;
  }

  .MuiStepper-root {
    padding: 24px 0 !important;
  }

  .stepBox {
    width: initial !important;
  }

  
  .userData {
    
    margin-right: 0em; 
 }

  .davcnaForm h1 {
    font-size: 24px;
  }

  .davcnaForm p {
    font-size: 12px;
  }

  .MuiFormControl-fullWidth {
    width: 95% !important;
  }

  .MuiTypography-body1 {
    font-size: 12px !important;
  }

  .stepBoxControll {
    margin: 2% 0 5% 0;
  }

  .PrivateSwitchBase-input-12 .MuiSvgIcon-root {
    width: 15px !important;
    height: 15px !important;
  }

  #audioDisabled {
    position: absolute;
    bottom: 21%;
    left: 3%;
}

  .stepBox button {
    padding: 1em 1em;
  }

  .stepBoxControll {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50%, 3fr));
    width: 100%;
  }

  .stepBoxFinal {
    width: 95%;
  }



  #remote-media-div {
    position: absolute;
    bottom: 12%;
    top: initial;
    right: 5%;
    width: 40vw;
    height: 24vh;
  }

  #remote-media-div video:last-of-type {
    display: none!important;
  }

  #remote-media-div video {
    width: 100%;
    height: 23vh;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
  }

  #local-media video {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .control {
    margin: 0 8%;
  }

  #logo {
    position: absolute;
  }

  .videoWelcomeScreen img {
    height: 25vh;
    width: 100%;
  }

  .videoWelcomeScreen h1 {
    font-size: 18px;
  }

  .videoWelcomeScreen h3 {
    font-size: 14px;
  }

  .videoWelcomeScreen button {
    margin-top: 5%;
    background: #5cc8bd;
    border-radius: 8px;
    font-size: 26px;
    color: #fff;
    border: 1px solid #5cc8bd;
    padding: 5% 10%;
    font-size: 18px;
  }

  .cancelingCall {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    padding: 3% 4.5%;
    background: #fff;
    border-radius: 7px;
    font-size: 26px;
    color: #aeaeae;
    min-width: 85vw;
    z-index: 1000;
  }

  .cancelingCall p {
    font-size: 18px;
  }

  .cancelingCallAction {
    border-top: 1px solid #aeaeae;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 5%;
    margin-bottom: 2%;
    align-items: center;
  }

  .logo-relative-mobile {
    position: relative !important;
  }

  .potrdi {
    border: 3px solid #5cc8bd;
    color: #5cc8bd;
    margin-left: 0;
    padding: 0.35em 1.5em;
    border-radius: 5px;
  }
}


/* // Medium devices (tablets, 768px and up) */
@media (max-width: 1224px) {
  /* Feedback CSS styling for Mobile */

.header{
  display: none!important;
}  

.login{
  display: none;
}

.doctor-panel-resize-app{
 z-index: 1000;
 background: #000;
 position: absolute;
 width: 100vw;
 height: 100vh;
 background-repeat: no-repeat;
 background-size: contain;
 background-image: url("./assets/uppershape.svg"), url("./assets/lowershape.svg");
 background-position: top right, bottom left;
}

.resize-window{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background: #F4D7D4;
  border-radius: 20px;
}

.resize-window-header{
  background: #F4D7D4;
  text-align: center;
  padding: 5%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
}

.resize-window-description{
  padding: 5%;
  background: #C4385B;
  text-align: center;
  color: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.resize-window-header h3{
  font-size: 30px;
  font-family: 'Noto Serif', serif!important;
  font-weight: bold;
}

.resize-window-description p{
  font-family: "Proxima Nova"!important;
}

.resize-window-header img{
  margin-right: 2em;
}


.doctor-panel{
 display: none;
}
}

/* // Medium devices (tablets, 768px and up) */
@media (max-width: 1360px) {
   /* Feedback CSS styling for Mobile */

#waitingForUser{
  font-size: 1em!important;
}

#audioDisabled {
  position: absolute;
  bottom: 12%;
  left: 4%;
}

.video-grid {
  height: 90vh;
  min-height: 90vh;
  background: #000;
  display: flex;
  flex-direction: row;
}

   .new-timer {
    position: absolute!important;
    right: unset !important;
    bottom: 9%!important;
    right: -8%!important;
    font-size: 1.2em;
    display: flex;
    flex-direction: row;
    width: 3em;
}

   #doctorOpinion .feedbackForm {
     padding: 2em;
   }

   .canceledDiv .buttons {
    display: grid !important;
    justify-content: space-around;


}

   html,
   body {
     font-family: ProximaNovaRegular, sans-serif;
     height: 100%;
   }
 
   .button {
     min-width: initial !important;
     /* padding: 0 8em!important; */
   }
 
   .timer {
    position: absolute;
    color: white;
    bottom: -18%;
    font-size: 26px;
    left: -40%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.2);
    padding: 2px 5px;
    width: 3.5em;
    justify-content: center;
    text-align: center;
   }
 
   .feedbackFormRatingOptions .item {
     display: flex;
     justify-content: center;
     align-items: center;
     align-content: center;
     border-radius: 8px;
     margin: 4% 2%;
     max-height: 6vh;
   }
 
   #feedbackRoot {
     box-sizing: border-box;
     display: flex;
     flex-direction: column;
     justify-content: center;
     background: #fff;
     overflow-x: hidden;
   }
 
   .feedbackFormRatingOptions > .selected h5 {
     color: #fff;
   }
 
   .feedbackFormRatingOptions > .selected {
     background: red;
     max-height: 6vh;
   }
 
   .feedbackForm h1 {
     font-size: 18px;
   }
 
   .feedbackForm h2 {
     font-size: 16px;
     margin: 3em 0 2em 0;
     padding: 0 9%;
   }
 
   .feedbackForm h3 {
     font-size: 12px;
     padding: 0 4em;
   }
 
   .feedbackFormCentered h1 {
     font-size: 28px;
   }
 
   .feedbackFormCentered h2 {
     font-size: 20px;
     margin: 0.5em 0 2em 0;
   }
 
   .feedbackView {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-content: center;
     flex: 1 1;
     color: #000;
     max-width: 100vw;
     margin: 1em 0;
   }
 
   .feedbackFormCentered {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-content: center;
     flex: 1 1;
     color: #000;
     max-width: 100vw;
     margin: 1em 0;
     padding: 2%;
     background: none;
   }
 
   .feedbackFormRating {
     padding: 8% 2%;
     display: grid;
     grid-template-columns: repeat(auto-fill, minmax(20%, 3fr));
   }
 
   .stepBoxFeedback {
     background: none;
     border-radius: 0;
     padding: 0;
     display: flex;
     flex-direction: column;
     flex: 1;
   }
 
   .makeStyles-instructions-3 {
     flex: 1;
   }
 
   .feedbackFormRating .item img {
     width: auto;
     height: 6vh;
   }
 
   .feedbackFormRating .item h4 {
     font-size: 12px;
     font-weight: normal;
   }
 
   .MuiOutlinedInput-input {
     padding: 11.5px 10px;
   }
 
   #logo {
     position: relative;
     display: flex;
     justify-content: center;
     margin: 0em 1em;
   }
 
   #logo img {
     height: 6vh;
   }
 
   .feedbackFormRatingOptions {
     padding: 0 5%;
     display: grid;
     grid-template-columns: repeat(auto-fill, minmax(50%, 3fr));
   }
 
   .halfGrid {
     grid-template-columns: repeat(auto-fill, minmax(50%, 3fr));
   }
 
   .fullRow {
     grid-template-columns: repeat(auto-fill, minmax(100%, 3fr));
   }
 
   .imageResponsive {
     height: auto !important;
     width: 80% !important;
   }
 
   /* Qeustionary CSS styling for Mobile */
 
   .makeStyles-padded-4 {
     padding: 0 !important;
   }
 
   /* .MuiSvgIcon-root {
     width: 5px !important;
     height: 5px !important;
   }
 
   .MuiStepConnector-alternativeLabel {
     top: 3px !important;
   }
 
   .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
     font-size: 10px !important;
   } */
 
   .MuiStepper-root {
     padding: 24px 0 !important;
   }
 
   .stepBox {
     width: initial !important;
   }

   .userData {
    
   margin-right: 0em; 
}
 
   .davcnaForm h1 {
     font-size: 24px;
   }
 
   .davcnaForm p {
     font-size: 12px;
   }
 
   .MuiFormControl-fullWidth {
     width: 95% !important;
   }
 
   .MuiTypography-body1 {
     font-size: 12px !important;
   }
 
   .stepBoxControll {
     margin: 2% 0 5% 0;
     
   }
 
   .PrivateSwitchBase-input-12 .MuiSvgIcon-root {
     width: 15px !important;
     height: 15px !important;
   }
 
   .stepBox button {
     padding: 1em 1em;
     margin: 0.5em;
   }
 
   .stepBoxControll {
    display: grid;
grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
width: 95%;
left: -2.5%;
position: relative;
   }
 
   .stepBoxFinal {
     width: 95%;
   }
 
   #remote-media-div {
     position: absolute;
     bottom: 12%;
     top: initial;
     right: 5%;
     width: 40vw;
     height: 24vh;
   }
 
   #remote-media-div video {
     width: 100%;
     height: 23vh;
     border-radius: 10px;
     object-fit: cover;
     object-position: center;
   }
 
   #local-media video {
     width: 100%;
     object-fit: cover;
     object-position: center;
   }
 
   .control {
     margin: 0 8%;
   }
 
   #logo {
     position: absolute;
   }
 
   .videoWelcomeScreen img {
     height: 25vh;
     width: 100%;
   }
 
   .videoWelcomeScreen h1 {
     font-size: 18px;
   }
 
   .videoWelcomeScreen h3 {
     font-size: 14px;
   }
 
   .videoWelcomeScreen button {
     margin-top: 5%;
     background: #5cc8bd;
     border-radius: 8px;
     font-size: 26px;
     color: #fff;
     border: 1px solid #5cc8bd;
     padding: 5% 10%;
     font-size: 18px;
   }
 
   .cancelingCall {
     position: absolute;
     left: 50%;
     top: 40%;
     transform: translate(-50%, -50%);
     padding: 3% 4.5%;
     background: #fff;
     border-radius: 7px;
     font-size: 26px;
     color: #aeaeae;
     min-width: 85vw;
     z-index: 1000;
   }
 
   .cancelingCall p {
     font-size: 18px;
   }
 
   .cancelingCallAction {
     border-top: 1px solid #aeaeae;
     display: flex;
     flex-direction: row;
     justify-content: space-around;
     padding-top: 5%;
     margin-bottom: 2%;
     align-items: center;
   }
 
   .logo-relative-mobile {
     position: relative !important;
   }
 
   .potrdi {
     border: 3px solid #5cc8bd;
     color: #5cc8bd;
     margin-left: 0;
     padding: 0.35em 1.5em;
     border-radius: 5px;
   }
}

/* // Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
  
}

/* Checkbox element, when checked */
input[type="checkbox"]:checked {
  background-color: #a7d586;
}


.doctorOppinionform textarea, select{
  border: 1px solid #A6A6A6;
border-radius: 8px;
margin: auto !important;
display: block;
padding: 20px !important;
color:  #959595 !important;
}

.doctorOppinionform h3{
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 45px;
}

.doctorOppinionform button{
  margin-top: 45px;
}
#doctorOpinion #feedbackRoot{
  height: unset;
  display: block;
  overflow-x: hidden;
  
}

#doctorOpinion #logo{
  position: relative;
  top:2em;
  left: 2em;
}
#doctorOpinion .feedbackView{
  margin: auto;
  display: block;
  padding-bottom: 3em;
}


.canceledDiv h2{
text-align: center;
font-size: 1.9em;
font-weight: bold;
}


.canceledDiv .buttons{
  display: flex;
  justify-content: space-around;
  /* grid-template-columns: repeat(auto-fill, minmax(50%, 2fr)); */

}

.canceledDiv button{
  background: #E0E0E0 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 1.2em 7em;
  margin-top: 43px;
}

.canceledDiv .canceledButton{
  background: #C4385B 0% 0% no-repeat padding-box;
  color: #fff;
}

.canceledDiv .canceledButton:hover{
  background: crimson;
}

.canceledWiew .stepBoxFeedback{
  padding: 20% 5%;
}

.preview-box {
  display:flex;
  align-items:center;
  width: calc(100% - 30px);
  padding: 10px 3%;
  background: #fff;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}
 
.preview-box img {
  max-height: 80px;
  max-width: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
 
.preview-box .name {
  font-style: italic;
  color: #666;
  margin-right: 7px;
}
 
.preview-box .percent {
  font-weight: 600;
}
 
.preview-box .status {
  margin-left: 7px;
  font-weight: 600;
  text-transform: uppercase;
}

.dzu-inputLabel{
  color: #757575 !important;
  font-family: Boston !important;
  font-size: 0.9em !important;
  font-weight: 400 !important;
  height: 230px !important;
}


.dzu-dropzone {
  
  height: 158px !important;
  overflow: hidden !important;
}

/* .enableAudio{
  display:block !importnat;
} */

#audioDisabled{
  position: absolute;
  bottom: 12%;
  right: 29%;
}

.new-timer{
  position: absolute;
  right: 31%;
  bottom: 1px;
  font-size: 1.2em;
}

.drfile{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #a5d782;
  border-radius: 8px;
  padding: 12px;
  left: 1%;  
}

.drfile li{
  list-style: none;
    font-family: Boston;
    color: #757575;
    border-bottom: 1px solid #A6A6A6;
    padding-top: 11px;
    padding-bottom: 10px;
}

.dataDivider h3 {
font-family: Boston !important;
color: #566964;
}

.dataDivider h2{
  color: #19191A;
  font-family: Boston!important;
}

.dataDivider h3 span{
  font-family: Boston !important;
}

.dataDivider textarea {
  color: #555555;
  font-family: Boston!important;
}

.dataDivider h4{
  font-family: Boston!important;
  color: #555555;

}

.fieluploaded{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.fieluploaded:last-child{
  border-bottom: none;
}


/* .sivo{
  background: rgba(222,222,222,0.7);
    width: 100%;
    height: 100vh;
    position: absolute;
} */

.test{
  background: rgba( 225,225,225,0.2);
  margin-left: 14vw;
  padding-left: 100vw;
  margin-right: 49vw;
  padding-right: 6vw;
  margin-bottom: -78vh;
  padding-bottom: -48px;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: none !important;
  overflow: none !important;
  /* overflow: hidden; */
  position: fixed;
  top: 0;
  left: 0;
}


.invisible{
  color: #fff!important;
}

.active-menu-item  {
  filter: invert(48%) sepia(86%) saturate(2880%) hue-rotate(313deg) brightness(92%) contrast(66%);
}

#waitingForUser{
  color: white;
  font-size: 2em;
}

.containrforPrivacyPolicy{
  width: 80%;
  margin:auto;
}